.Home {
    

    /* background: rgb(68,37,89);
    background: linear-gradient(173deg, rgba(68,37,89,1) 0%, rgba(96,199,217,1) 70%, rgba(143,255,199,1) 100%);

    background: rgb(41,17,57);
    background: linear-gradient(167deg, rgba(41,17,57,1) 2%, rgba(109,84,130,1) 50%, rgba(209,161,177,1) 90%);

    background: rgb(163,78,124);
    background: linear-gradient(167deg, rgba(163,78,124,1) 2%, rgba(109,84,130,1) 50%, rgba(209,161,177,1) 90%);

    background: rgb(221,53,144);
    background: linear-gradient(167deg, rgba(221,53,144,1) 2%, rgba(209,161,177,1) 71%, rgba(109,84,130,1) 94%);

    background: rgb(104,122,177);
    background: linear-gradient(167deg, rgba(104,122,177,1) 2%, rgba(209,161,177,1) 71%);

    background: rgb(167,181,255);
    background: radial-gradient(circle, rgba(167,181,255,1) 0%, rgba(252,70,107,1) 100%); 

    background: rgb(0,212,255);
    background: linear-gradient(173deg, rgba(0,212,255,1) 0%, rgba(96,199,217,1) 28%, rgba(143,255,199,1) 100%);

    background: rgb(26, 26, 26);
    background-image: url('../../images/hi_res_codeBG.jpg');
    background-image: url('../../images/codeBG2.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.95); */
    

    height: 100vh;
    position: relative;
    display: flex;

}


.Header {
    position: relative;
    margin: auto; 
    /* margin-left: 10%;  */
    color: white; 
    /* background-color: black; */
    /* text-align: left; */
 
    

}

.Header h1 {
    font-size: 8rem;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    position: relative;
    /* font-family: 'Josefin Sans', sans-serif;
    font-family: 'Lexend', sans-serif; */
    color: rgb(184, 109, 109);
    color: rgb(255, 255, 255);
}

.SubHead {
    display: flex;
    width: auto;
    /* border-top: 1px solid white; */
    padding: 10px 0px; 
    justify-content: center;
    position: relative;
    margin-top: -10%; 
    letter-spacing: 1px;
    color: rgb(167, 97, 97);
    color: rgb(138, 204, 138);
    font-family: 'Lexend', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    /* font-weight: bold; */
}

.SubHead div {
    font-size: 1.2rem;
    padding: 0px 10px; 
}

.SocialMediaLinks {
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
    padding-top: 50px;
}

.SocialMediaLinks a {
    color: white;
    text-decoration: none;
}


.Icon {
    padding: 0px 50px;
    font-size: 4rem;
}

.Icon:hover{
    color: rgb(138, 204, 138);

}

@media screen and (max-width: 500px){

    .Home {
        height: 100vh;
        position: relative; 
    }
    .Header h1 {
        font-size: 4rem;
        
    }

    .SubHead {
        display: block;
    }

    .SubHead div {
        font-size: 0.8rem;
        padding: 5px 0%; 
    }

    .SocialMediaLinks{
        width: 100%;
        padding-top: 75px;
    }
    .Icon {
        font-size: 3.8rem;
    }
}
