.MobileNav {
    display: none; 
}

@media screen and (max-width: 500px){

    .Wrapper {
        height: 100vh; 
        position: fixed;
        top: 0px;
        left: 0;
        width: 100vw; 
        z-index: 1000;
        
    }
    .MobileNav {
        display: block;
        position: fixed;
        height: 75px; 
        background: black; 
        width: 100%;
        color: white; 
        z-index: 1000;
        
    }

    .Burger {
        font-size: 2rem; 
        margin-left: auto;
        padding-right: 25px; 
        padding-top: 25px; 
        
        
    }

    .NavAction {
        display: flex; 
        width: 100%; 
    }

    .NavClosed {
        /* display: none;  */
        transform: translateX(100%);
        transition: .5s ease-in-out;
        position: relative;
        background: black;
        height: 100vh;
    }

    .NavClosed ul {
        padding-top: 40%;
        list-style: none;
        color: white; 
        list-style-position: inside;
        padding-left: 0;
    }

    .NavClosed ul li {
        padding: 20px 0px;
    }

    .NavClosed a {
        color: white; 
        text-decoration: none;
        font-size: 3rem; 
        font-family: 'Playfair Display', serif;
    }

    .NavItems {
        position: relative;
        background: black;
        height: 100vh;
        transition: .5s ease-in-out;

    }

    .NavItems ul {
        padding-top: 40%;
        list-style: none;
        color: white; 
        list-style-position: inside;
        padding-left: 0;
    }

    .NavItems ul li {
        padding: 20px 0px;
    }

    .NavItems a {
        color: white; 
        text-decoration: none;
        font-size: 3rem; 
        font-family: 'Playfair Display', serif;
    }
}