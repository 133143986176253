.PortfolioItem {
    width: 650px;
    height: 320px;
    margin: auto; 
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    font-family: 'Lexend', sans-serif;
   
    padding: 5px;     
   
}

.Header {
    position: relative; 
    margin: auto;
    text-align: center;
    padding-top: 15px;
}

.Header h2 {
    font-size: 1.5rem;
    transition: 0.5s ease-in-out;
    font-family: arial;
    color: rgb(167, 90, 90);
    color: rgb(162, 201, 162);

}

.Details {
    position: relative;
    margin: auto;
    width: 50%;
    
    
    
     
}

.Details p {
    line-height: 1px;
    color: rgb(163, 163, 163);
    
}

.Details ul {
    font-size: 1rem;
    list-style: none;
    
}

.ProjectInfoOff {
    transform: translateY(-110%);
    
}

.ProjectInfo {
    display: block;
    transition: 0.25s ease-in-out;
    
}

.Action {
    display: flex; 
    width: 50%;
    margin: auto; 
    text-align: center;
    position: relative;
    padding-top: 20px;

    
    
}

.Action a {
    text-decoration: none;
    color: white; 

}

.Action a:hover {
    color: rgb(255, 255, 255);
}

.Action p {
    margin: auto;
    min-width: 130px;
    font-size: 1.4rem;
    border: 1px solid rgb(135, 189, 181); 
    padding: 5px 10px;
    background-color: rgb(104, 197, 166);
    background-color: rgb(0, 0, 0);
    
}

.Action p:hover {
    color: rgb(162, 201, 162); 
    color: rgb(255, 255, 255);

    background-color: rgb(154, 231, 205);
    background-color: rgb(125, 197, 173);

    
}

.PortfolioItem:hover {
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.95);
    transition: .25s ease-in-out;
    /* border-bottom: 2px solid rgb(218, 121, 121); */
    /* border: 2px solid rgb(162, 201, 162); */

    
}

@media screen and (max-width: 500px){
    .PortfolioItem {
        width: 100%; 
        height: 200px; 
        
    }

    .Header {
        position: relative; 
        margin: auto;
        text-align: center;
        padding-top: 5px;
    }
    
    .Header h2 {
        font-size: 1rem;
        transition: 0.5s ease-in-out;
        font-family: arial;
        color: rgb(167, 90, 90);
        color: rgb(162, 201, 162);
    
    }
    
    .Details {
        position: relative;
        margin: auto;
        width: 50%;
           
         
    }
    
    .Details p {
        line-height: 1px;
        color: rgb(163, 163, 163);
        font-size: .5rem;
        
    }
    
    .Details ul {
        font-size: .75rem;
        list-style: none;
        
    }

    .Action {
        display: flex; 
        width: 90%;
        margin: auto; 
        text-align: center;
        position: relative;
        padding-top: 10px;
    
        
        
    }

    .Action p {
        margin: auto;
        min-width: 130px;
        font-size: 1.1rem;
        border: 1px solid rgb(135, 189, 181); 
        padding: 10px 10px;
        background-color: rgb(104, 197, 166);
        background-color: rgb(0, 0, 0);
        
    }

}

@media screen and (max-width:390px){
    .PortfolioItem {
        height: 190px;
    }

    .Action p {
        font-size: 1rem;
        padding: 5px 10px; 
    }
}