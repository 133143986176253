.Layout {
    /* background-image: url('../../images/hi_res_codeBG.jpg'); */
    background-image: url('../../images/codeBG2.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.85);
}

@media screen and (max-width: 1000px){
    .Layout {
        background-size: 100vh 100%;
    }
}

@media screen and (max-width: 500px){
    .Layout {
        /* background-size: cover; */
        background-position-x: 100% ;
    }
}