.Contact {
    padding-top: 10%; 
    width: 100%; 
    height: 100vh; 
    color: white;
    font-family: 'Playfair Display', serif;

}

.Contact h1 {
    font-size: 6rem; 
    font-family: 'Playfair Display', serif;
}

.Contact h3 {
    font-size: 2rem; 
    letter-spacing: 2px;
    font-family: 'Lexend', sans-serif;
    font-family: 'Josefin Sans', sans-serif;

    
}

.Contact a {
    color:white;
    text-decoration: none;
}

.Contact a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 500px){
    .Contact {
        padding-top: 150px; 
    }
    
    .Contact h1 {
        font-size: 3rem;
    }

    .Contact h3 {
        font-size: 1.2rem; 
    }
}