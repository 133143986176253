.About {
    position:relative;
    color: white;
    padding-bottom: 15%;
    font-family: 'Lora', serif;
    margin: auto;
    
    
}

.About h1 {
    font-size: 5rem;
    font-family: 'Playfair Display', serif;
}

.About h2 {
    font-size: 3rem;
    letter-spacing: 1px;
}

.Introduction {
    color:  rgb(138, 204, 138);
    margin: auto;
    width: 35%;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 2px; 
}

@media screen and (max-width: 1440px){
    .About {
        padding-bottom: 20%; 
    }
}

@media screen and (max-width: 1000px){
    .About {
        width: 90%; 
        padding-bottom: 35%;
    }

    .About h1 {
        font-size: 4rem;
    }

    .About h2 {
        font-size: 2rem;
    }

    .Introduction {
        width: 80%; 
        text-align: justify;
    }
}

@media screen and (max-width: 500px){
    .About h1 {
        font-size: 3rem; 
    }

    .About h2 {
        font-size: 1.5rem;
    }

    .Introduction {
        padding-top: 25px;
        font-size: 1.2rem; 
    }
}