body {
    margin: 0;
}

.Portfolio {
    min-height: 100vh;
    height: 100%;  
    /* background: black; */
    color: white; 
    margin: auto; 
    text-align: left;
    padding-bottom: 25%; 
}

.InnerContainer {
    width: 70%; 
    margin: auto;
    position: relative;
    padding-top: 10%;
}

.Header {
    position: relative; 
    padding-bottom: 5%; 
    
}

.Header h1 {
    font-size: 4rem;
    font-family: 'Playfair Display', serif;
    text-align: center;
}

.Items {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-start;
    -ms-flex-wrap: wrap;
    
}

.Loading {
    margin: auto;
    text-align: center;
    font-size: 1.5rem; 
}

@media screen and (max-width: 1440px){
    .Header {
        padding-top: 50px;
    }
}

@media screen and (max-width: 500px){
    .Header h1 {
        font-size: 2.5rem; 
    }

    .InnerContainer {
        width: 95%;
    }
}