.Footer {
    height: 5vh;
    width: 100%;
    background-color: black;
    color: rgb(99, 99, 99); 
    position: relative;
    bottom: 0; 
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Lexend', sans-serif;
    letter-spacing: 1.5px;
    padding-top: 10px;
}