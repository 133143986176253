.Skills {
    height: 50vh;
    width: 50%; 
    /* display: flex;
    justify-content: space-between; */
    position: relative;
    margin: auto;
    color: white;
    padding-bottom: 20%; 
}

.Skills a {
    color: white; 
    text-decoration: none;
}

.Header h1 {
    font-size: 6rem; 
    font-family: 'Lora', serif;
    
    
}

.SkillsSummary {
    width: 85%; 
    color: rgb(123, 173, 123);
    color: rgb(138, 204, 138);
    /* color: rgb(179, 179, 179); */
    font-family: 'Lora', serif;
    margin: auto; 
    letter-spacing: 1.5px;
    line-height: 2;
    font-size: 1.5rem;
    padding-bottom: 5%;
    text-align: justify;
}

.SkillsContainer {
    width: 100%; 
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: auto;

}

.SkillBox {
    display: block; 
    width: 25%;

}

.SkillBox h3 {
    font-size: 2.5rem;
    color: rgb(138, 204, 138);
    /* text-align: left; */
    font-family: 'Playfair Display', serif;
}

.SkillBox ul {
    margin: auto;
    /* text-align: left;  */
    list-style-position: inside;
    list-style: none;
    padding-left: 0;
}

.SkillBox ul li {
    line-height: 1.5;
    letter-spacing: 2px;
}

.Action {
    margin: auto; 
    margin-top: 15%; 
    display: flex;
    justify-content: space-evenly;
}

.PortfolioBtn {
    font-family: 'Lexend', sans-serif;
    font-family: 'Playfair Display', serif;
    width: 300px;
    height: auto; 
    padding: 10px 0px; 
    color: white; 
    cursor: pointer;
    margin: auto;
    border: 1px solid rgb(48, 129, 113);  
    background-color: rgb(0, 0, 0);
    
}

.PortfolioBtn:hover {
    background-color: black;
    color: rgb(138, 204, 138);
    border: 1px solid rgb(138, 204, 138);

}

@media screen and (max-width: 1440px){
    .Skills {
        padding-bottom: 55%; 
        width: 70%;
    }

    
}

@media screen and (max-width: 1000px){
    .Skills {
        width: 90%; 
        padding-bottom: 100%; 
    }

    .Header h1 {
        font-size: 5rem;
    }

    .SkillsContainer {
        width: 90%; 
        padding-top: 10%; 
    }

    .SkillBox h3 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 500px){
    .Skills {
        height: 100%;
        padding-bottom: 50%; 
    }

    .Header h1 {
        font-size: 3rem; 
    }

    .SkillsSummary {
        font-size: 1.2rem;
        color: rgb(138, 204, 138);
        text-align: center;
    }

    .SkillsContainer {
        display: block;
        width: 100%; 
        margin: auto;
    }

    .SkillBox {
        width: 100%; 
    }

    .Action {
        margin-top: 35%; 
    }

    .PortfolioBtn {
        color: rgb(138, 204, 138);
        border: 1px solid rgb(138, 204, 138);
        background-color: black;
    }
}